body {
    background: #fff;
    color: #1c1c1c;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    overflow-x: hidden;
}

/* Reset
======================= */
a, a:focus, a:hover, button, button:focus, button:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    outline: none;
    text-decoration: none;
}

@media (max-width: 500px) {
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11 {
        width: 100%;
    }
}