#video {
    background: url("../../images/bg-video.jpg") center center no-repeat fixed;
    color: #fff;
    min-height: 517px;
    padding: 40px 0;
    text-align: center;
}

#video h1 {
    font-size: 35px;
    font-weight: 600;
}

#video h1 span {
    font-weight: 800;
}

#video h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    margin: 0 auto 40px;
    max-width: 500px;
}

#video img {
    display: inline-block;
}

#video .modal .modal-dialog {
    max-width: 900px;
    width: 90%;
}