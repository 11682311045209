.offset-canvas-mobile {
    background-color: #121212;
    bottom: 0;
    left: 0;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: transform 1s cubic-bezier(1, 0, 0, 1);
    -o-transition: transform 1s cubic-bezier(1, 0, 0, 1);
    -webkit-transition: transform 1s cubic-bezier(1, 0, 0, 1);
    z-index: 1200;
}

.offset-canvas-mobile > div {
    height: 100%;
    overflow: auto;
    padding-right: 20px;
    width: 100%;
}

.offset-canvas-mobile ul {
    list-style: none;
    margin-top: 30px;
    padding: 0;
}

.offset-canvas-mobile hr {
    margin: 10px 0;
}

.offset-canvas-mobile hr + ul {
    margin-top: 10px;
}

.offset-canvas-mobile .mobile-nav li {
    border-bottom: 1px solid #191919;
    padding: 10px 0;
    position: relative;
}

.offset-canvas-mobile .mobile-nav li a {
    color: #fff;
    display: inline-block;
    padding: 0;
    text-transform: uppercase;
    width: calc(100% - 30px);
}

.offset-canvas-mobile .mobile-nav li .toggle-submenu {
    top: -2px;
}

.offset-canvas-mobile li a:hover, .offset-canvas-mobile .mobile-nav li a:hover, .offset-canvas-mobile .mobile-nav li a:focus, .offset-canvas-mobile .mobile-nav li a:active {
    color: #2F86FA !important;
    text-decoration: none;
}

.offset-canvas-mobile .mobile-nav li ul {
    display: none;
    margin: 0;
    padding-left: 20px;
}

.offset-canvas-mobile .mobile-nav li ul li:last-child {
    border: none;
}

.offset-canvas-mobile .mobile-nav li ul a {
    font-size: 90%;
}

.offset-canvas-mobile .mobile-nav li.has-submenu > span {
    font-size: 20px;
    height: 20px;
    padding: 1px 10px 0;
}

.offset-canvas-mobile .mobile-addits li {
    display: inline-block;
    margin: 0 5px;
}

.offset-canvas-mobile .mobile-addits li a {
    background-color: #fff;
    color: #000;
    display: inline-block;
    padding: 10px;
}

.offset-canvas-mobile.open-canvas {
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    -webkit-transform: translateX(0);
}

.offset-canvas-mobile .close-canvas {
    color: #fff;
    font-size: 25px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 5;
}

.offset-canvas-mobile .close-canvas:focus, .offset-canvas-mobile .close-canvas:active {
    text-decoration: none;
}

.offset-canvas-mobile .list-inline {
    text-align: center;
}

.offset-canvas-mobile .list-inline li {
    line-height: 25px;
}

.offset-canvas-mobile .list-inline i {
    font-size: 15px;
    margin-right: 2px;
}