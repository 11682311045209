a#back-to-top, a#back-to-top:focus {
    background-color: #0076D9;
    border-radius: 4px;
    bottom: 10px;
    color: #fff;
    display: none;
    height: 50px;
    line-height: 45px;
    position: fixed;
    right: 10px;
    text-align: center;
    width: 50px;
}

a#back-to-top:hover {
    background-color: #010302;
}