#footer {
    background: url("../../images/bg-bottom.jpg") no-repeat top center;
    min-height: 748px;
    padding-top: 30px;
    position: relative;
    text-align: center;
}

#footer .img-absolute {
    bottom: 0;
    position: absolute;
}

#footer .img-absolute.phone-black {
    left: 50%;
    margin-left: -30px;
}

#footer .img-absolute.phone-white {
    left: 50%;
    margin-left: -250px;
    z-index: 1;
}

#footer h2 {
    color: #0076D9;
    font-size: 40px;
    font-weight: 200;
    line-height: 55px;
    margin-bottom: 30px;
}

#footer h2 strong {
    font-weight: 900;
}

#footer h2 + a {
    display: inline-block;
    margin-bottom: 400px;
}

#footer .btn-download {
    display: inline-block;
    width: 100%;
}

#footer .btn-download.apple {
    text-align: right;
}

#footer .btn-download.google {
    text-align: left;
}

#footer .btn-download img {
    display: inline-block;
}

@media (max-width: 991px) {
    #footer {
        min-height: 384px;
        overflow: hidden;
    }

    #footer h2 + a {
        margin-bottom: 40px;
    }

    #footer .img-absolute {
        bottom: auto;
    }

    #footer .img-absolute.phone-white {
        margin-left: -340px;
        top: 80px;
        width: 240px;
    }

    #footer .img-absolute.phone-black {
        margin-left: -230px;
        top: 110px;
        width: 220px;
    }
}

@media (max-width: 767px) {
    #footer {
        padding: 20px 0 40px;
    }

    #footer .img-absolute {
        display: none;
    }

    #footer .btn-download.apple, #footer .btn-download.google {
        text-align: center;
    }
}