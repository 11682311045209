.headroom {
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;    
}

.headroom--pinned {
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    -webkit-transform: translateY(0%);
}

.headroom--unpinned {
    transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
}