#call-to-action {
    background: url("../../images/bg-topo.jpg") no-repeat top center;
    min-height: 742px;
    padding-top: 150px;
    position: relative;
}

#call-to-action > img {
    bottom: 20px;
    left: 50%;
    margin-left: -11px;
    position: absolute;
}

#call-to-action .img-absolute {
    position: absolute;
}

#call-to-action .img-absolute.img-left {
    top: 60px;
    z-index: 1;
}

#call-to-action .img-absolute.img-right {
    right: 90px;
}

#call-to-action h1 {
    color: #027CD1;
    font-size: 40px;
    font-weight: 800;
}

#call-to-action h3 {
    color: #31404b;
    font-size: 30px;
    font-weight: 200;
    margin: 10px 0 20px;
}

#call-to-action ul {
    margin: 20px 0 60px;
    padding: 0;
}

#call-to-action ul li {
    margin-right: 15px;
    padding: 0;
}

@media (max-width: 991px) {
    #call-to-action > img {
        display: none;
    }
}

@media (max-width: 767px) {
    #call-to-action {
        min-height: auto;
        text-align: center;
    }
    #call-to-action .img-absolute {
        display: none;
    }
}