#plans {
    padding: 50px 0;
    text-align: center;
}

#plans h1 {
    color: #027CD1;
    font-size: 35px;
    font-weight: 600;
}

#plans h1 span {
    font-weight: 800;
}

#plans ul {
    margin: 20px 0 60px;
    padding: 0;
}

#plans ul li {
    margin: 0 15px;
}

#plans .plan, #plans .plan a, #plans .plan a:focus {
    color: #31404b;
}

#plans .plan {
    margin-bottom: 50px;
}

#plans .plan h3 {
    font-size: 20px;
}

#plans .plan h3 span {
    font-weight: 800;
}

#plans .plan p {
    color: #415463;
    font-weight: 200;
    line-height: 25px;
}