#header {
    display: inline;
    float: left;
    left: 0;
    padding: 15px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

#header .container {
    position: relative;
}

#header ul {
    margin: 30px 0;
    text-align: right;
}

#header ul li {
    margin-left: 15px;
    padding: 0;
}

#header ul li a, #header ul li a:focus {
    color: #31404b;
    /*color: #fff;*/
    font-size: 15px;
    text-transform: uppercase;
}

#header .open-nav {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
}

#header .open-nav i {
    font-size: 20px;
    color: #027CD1;
}

#header ul li a:hover {
    color: #027CD1;
}

#header.headroom--pinned.headroom--not-top {
    background: #242424;
}

#header.headroom--pinned.headroom--not-top ul li a, #header.headroom--pinned.headroom--not-top ul li a:focus {
    color: #fff;
}

#header.headroom--pinned.headroom--not-top ul li a:hover {
    color: #027CD1;
}

@media (max-width: 767px) {
    #header .logo img {
        max-width: calc(100% - 50px);
    }
}